import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  opacity: 0.5;
  z-index: 1;
  margin-top: 10px;
  width: ${props => props.width}px;
  margin-left: ${props => props.ml}px;
`;

const Text = styled.span`
  display: flex;
  color: white;
  font-size: 0.8rem;
  justify-content: center;
  user-select: none;
  &:hover {
    display: none;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: white;
  margin-top: 0px;
  &:hover {
    display: none;
  }
`;

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    display: none;
  }
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  background: white;
  margin-right: ${props => (props.mr ? props.mr : 0)}px;
  margin-left: ${props => (props.ml ? props.ml : 0)}px;
  border-radius: 50%;
`;

export { Container, Text, Line, LineContainer, Dot };
