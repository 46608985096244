import moment from 'moment';
import { weekYearToNumber, weekYearToObj, momentToWeekYear, textParser, calculateHint, maxTimesHabit } from './parsers';

export const getCurrentUsersWeek = (currentWeek, firstWeek) => {
  const { week: cWeek, year: cYear } = weekYearToObj(currentWeek);
  const { week: fWeek, year: fYear } = weekYearToObj(firstWeek);

  const firstWy = moment()
    .isoWeekYear(fYear)
    .isoWeek(fWeek);

  const currWy = moment()
    .isoWeekYear(cYear)
    .isoWeek(cWeek);

  const difference = currWy.diff(firstWy, 'weeks');

  return difference + 1;
};

export const getWeekYear = options => {
  const now = moment();

  if (options && options.numOfWeeks) {
    now.add(options.numOfWeeks, 'weeks');
  }

  if (options && options.withDate) {
    const weekyear = now.format('[W]WW[Y]GG');
    const date = now.startOf('isoWeek').format('MMM D');
    return { date, weekyear };
  }

  const weekyear = now.format('[W]WW[Y]GG');

  return weekyear;
};

export const dateAndWeekGenerator = options => {
  const { start, limit, momentFirstReview, start_day } = options;

  const weeks = [];
  for (var i = start; i <= limit; i++) {
    weeks.push(i);
  }

  const weeksAndDates = weeks.map((week, i) => {
    const { date, weekYear, momentObj } = calculateDate(week, momentFirstReview, start_day);

    return {
      week: `W${week}`,
      date,
      weekYear,
      momentObj,
    };
  });

  return weeksAndDates;
};

const momentFirstDate = firstDate => {
  const yearWeek = firstDate.toString();
  const year = `20${yearWeek.substring(0, 2)}`;
  const week = yearWeek.substring(2, 4);

  return moment()
    .isoWeekYear(year)
    .isoWeek(week)
    .startOf('isoWeek');
};

export const calculateFirstReview = (data, startDay) => {
  const { reviews } = data;

  const onlyDates = reviews.map(review => weekYearToNumber(review.weekyear));
  const firstDate = Math.min(...onlyDates);

  const parsedFirstDate = momentFirstDate(firstDate);

  return parsedFirstDate;
};

export const calculateStartAndLimit = (data, total) => {
  const { reviews } = data;

  const onlyDates = reviews.map(review => weekYearToNumber(review.weekyear));
  const numOfWeeks = [...new Set(onlyDates)].length + 1;

  let start = numOfWeeks - (total - 1);

  if (start < 1) {
    return {
      start: 1,
      limit: total,
    };
  }

  return {
    start,
    limit: numOfWeeks,
  };
};

const calculateDate = (week, startDate, weekStartDay) => {
  const newMoment = moment(startDate);
  let addOneDay = false;

  if (typeof weekStartDay === 'string') {
    if (Number(weekStartDay) === 7) {
      newMoment.subtract(1, 'day');
      addOneDay = true;
    }
  }

  newMoment.add(week - 1, 'weeks');

  return {
    date: newMoment.format('MMM D'),
    weekYear: momentToWeekYear(newMoment, addOneDay),
    momentObj: newMoment,
  };
};

export const getLogsOfTheWeek = (logs, settings, weeksBefore) => {
  const parsedSettings = JSON.parse(settings);
  const startDay = parsedSettings.start_day;

  const firstDayWeek = moment()
    .subtract(weeksBefore, 'weeks')
    .startOf('isoWeek');
  const lastDayWeek = moment()
    .subtract(weeksBefore, 'weeks')
    .endOf('isoWeek');

  if (Number(startDay) === 7) {
    firstDayWeek.subtract(1, 'day');
    lastDayWeek.subtract(1, 'day');
  }

  const weekLogs = logs.filter(l => {
    const logMoment = moment(l.date, 'YYYY-MM-DD');
    if (logMoment.isBetween(firstDayWeek, lastDayWeek, null, '[]')) {
      return l;
    }
  });
  return weekLogs;
};

export const calculateLastFourWeekYears = () => {
  const lastFourWeeks = [...Array(4)].map((el, i) => {
    const week = getWeekYear({ numOfWeeks: `-${i + 1}`, withDate: true });
    return week;
  });
  return lastFourWeeks.reverse();
};

export const getReviewDaysRange = (isPastWeek, settings) => {
  const parsedSettings = JSON.parse(settings);
  const startDay = parsedSettings.start_day;

  let weekyear;
  if (isPastWeek) {
    weekyear = getWeekYear({ numOfWeeks: -1 });
  } else {
    weekyear = getWeekYear();
  }

  const firstDayWeek = moment(weekyear, '[W]WW[Y]GG').startOf('isoWeek');

  if (Number(startDay) === 7) {
    firstDayWeek.subtract(1, 'day');
  }

  const lastDayWeek = moment(firstDayWeek).add('6', 'days');

  return `${firstDayWeek.format('MMM D')} to ${lastDayWeek.format('MMM D')}`;
};

export const checkIfCorrectAlertWeek = (week, isPastWeek) => {
  const { weekYear: buttonWeekYear } = week;

  let weekyear;
  if (isPastWeek) {
    weekyear = getWeekYear({ numOfWeeks: -1 });
  } else {
    weekyear = getWeekYear();
  }

  if (weekyear === buttonWeekYear) {
    return true;
  }
};

export const calculateLastWeekScore = (habit, habitReviews) => {
  const lastWeekReview = habitReviews.find(r => r.weekyear === getWeekYear({ numOfWeeks: -1 }));

  const scoreInText = textParser(lastWeekReview.score);
  const hint = calculateHint(lastWeekReview.score, maxTimesHabit(habit));

  return `${scoreInText}${hint}`;
};
