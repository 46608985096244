import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, CustomInput, Label, Row } from 'reactstrap';
// import Flex from '../../components/common/Flex';
// import FaqCol from '../../components/faq/FaqCol';
import Plan from '../../components/Plan';
import api from '../../services/api';

import { useAuth } from '../../context/AuthContext';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import moment from 'moment';

const plans = [
  {
    type: 'Starter',
    price: {
      month: 0,
    },
    icon: 'paper-plane',
    features: [
      { title: '3 habits' },
      { title: 'Add Accountability Partners' },
      { title: 'Send Unlimited Nudges by text' },
      { title: 'Weekly Reflections' },
      { title: 'Reports & Insights' },
      { title: 'Privacy Mode' },
      { title: 'Browser Extension' },
    ],
    button: {
      text: 'Free version',
      color: 'outline-primary',
    },
  },
  {
    type: 'Growth',
    price: {
      month: 7,
    },
    icon: 'paper-plane',
    features: [
      { title: 'Unlimited habits' },
      { title: 'Add Accountability Partners' },
      { title: 'Send Unlimited Nudges by text' },
      { title: 'Weekly Reflections' },
      { title: 'Reports & Insights' },
      { title: 'Privacy Mode' },
      { title: 'Browser Extension' },
    ],
    button: {
      text: 'Subscribe',
      color: 'primary',
    },
    priceId: process.env.REACT_APP_STRIPE_GROWTH_PLAN_PRICEID,
  },
];

export default function PlansView() {
  const urlParams = new URLSearchParams(window.location.search);

  const { user, updateUser } = useAuth();

  useEffect(() => {
    const verifyStatusAndSession = async () => {
      const status = urlParams.get('status');

      if (status === 'cancel') {
        toast.info('Checkout canceled.');
      }

      if (status === 'success') {
        const sessionId = urlParams.get('session_id');

        try {
          const { data: paymentSuccess } = await api.get(`/verify-checkout?sessionId=${sessionId}`);
          if (paymentSuccess) {
            updateUser('premium', true);
            toast.success('Payment verified!');
          } else {
            toast.info("Sorry, we couldn't verify your payment.");
          }
        } catch (err) {
          toast.error('Error verifying checkout.');
        }
      }
    };

    verifyStatusAndSession();
  }, []);

  const redirectToCustomerPortal = async () => {
    try {
      const { data } = await api.get('/customer');
      window.location.replace(data.url);
    } catch (err) {
      toast.error('There was an error trying to access the portal.');
    }
  };

  return (
    <>
      {!!user.premium && (
        <Row className="justify-content-center mt-3">
          <Col xs={12} lg={8}>
            <Card className="mb-3" style={{ background: 'transparent' }}>
              <FalconCardHeader title="Billing Settings" light={false} />
              <CardBody className="bg-light">
                <Row>
                  <Col xs={12} className="mb-2">
                    Hey, we've detected that you're already subscribed to the{' '}
                    <span className="font-weight-bold">Growth Plan</span>.
                  </Col>
                  <Col xs={12} className="fs--1 mb-2">
                    If you need to update your payment information, please access the Customer Portal.
                  </Col>
                  <Col xs={12}>
                    <Button className="m-0" color="primary" onClick={redirectToCustomerPortal}>
                      Go to Customer Portal
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      <Card className="mb-3" style={{ background: 'transparent' }}>
        <CardBody>
          <Row className="justify-content-center">
            <Col xs={12} className="text-center mb-4">
              <div className="fs-1">Weekup Plans</div>
              <h3 className="fs-2 fs-md-3">
                Starter plan with all the basic features. <br className="d-none d-md-block" />
                Growth plan for advanced users.
              </h3>
            </Col>
            <Col xs={12} lg={8}>
              <Row>
                {plans.map((pricingCard, index) => (
                  <Col xs={12} md key={index}>
                    <Plan {...pricingCard} alreadyHasPlan={user?.premium} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
