import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 5rem;
  min-height: 3.5rem;

  &:hover {
    border: ${props => !props.friend && '3px solid #edf2f9'};
    cursor: ${props => !props.friend && 'pointer'};
    z-index: 10;
  }

  &.disabled {
    &:hover {
      border: none;
      cursor: default;
    }
  }

  &:last-child {
    border-bottom-right-radius: ${props => props.table !== 'review' && '25px'};
    border-top-right-radius: ${props => props.table !== 'review' && '25px'};
  }
`;

export const DropDown = styled.div`
  position: absolute;
  background: white;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  top: 50%;

  width: auto;
  height: ${props => (props.withReflect ? '6rem' : '3.5rem')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border-radius: 3px;
  box-shadow: rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 5%) 0px 2px 4px;

  padding-bottom: ${props => props.withReflect && '.5rem'};
`;
