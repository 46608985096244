import React, { useState } from 'react';
import { Badge, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useData } from '../../../context/DataContext';

import * as S from './styled';
import { parseFrequency } from '../../../helpers/tracker-parsers/parsers';

const HabitName = ({ table, habit, today, friend, editingHabit, setEditingHabit }) => {
  const { data } = useData();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const parseCategory = categoryId => {
    const { liability } = data.categories.find(c => c.id === categoryId);
    return liability;
  };

  return (
    <S.Container table={table} data-private>
      <S.Text table={table} today={today} className="updateLog">
        <div className="name-box">
          <span
            className="name text-800 updateLog"
            id={'habit' + habit.id}
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}
          >
            {habit.name}
          </span>

          <Tooltip
            placement="top"
            isOpen={isTooltipOpen}
            target={'habit' + habit.id}
            toggle={() => setIsTooltipOpen(!isTooltipOpen)}
          >
            {habit.name}
          </Tooltip>

          {Boolean(habit.sample) && (
            <S.SampleLabel color={'soft-info'} pill className="updateLog">
              sample
            </S.SampleLabel>
          )}
          {!habit.active && (
            <S.SampleLabel color={'soft-secondary'} pill>
              inactive
            </S.SampleLabel>
          )}
          {table !== 'review' && !friend && (
            <S.EditButton
              className="edit-button"
              onClick={() => setEditingHabit(habit)}
              editing={editingHabit?.id === habit.id ? true : undefined}
              sample={Boolean(habit.sample)}
            >
              <FontAwesomeIcon icon="pencil-alt" />
            </S.EditButton>
          )}
        </div>
        <S.CategoryOrFrequency className="fs--1 updateLog" style={{ color: '#9fa0a2' }}>
          {table === 'review' ? parseFrequency(JSON.parse(habit.frequency)) : parseCategory(habit.category_id)}
        </S.CategoryOrFrequency>
      </S.Text>
    </S.Container>
  );
};
export default HabitName;
