import React, { useState, useContext, createContext, useEffect } from 'react';
import moment from 'moment';
import { useAuth } from './AuthContext';
import { useData } from './DataContext';
import { calculateStartAndLimit, dateAndWeekGenerator, getWeekYear } from '../helpers/tracker-parsers/helpers';
import {
  calculateImprovement,
  calculateScore,
  improvementColor,
  weekYearToNumber,
} from '../helpers/tracker-parsers/parsers';

const TableContext = createContext();

export const useTable = () => {
  const context = useContext(TableContext);

  return context;
};

export const TableProvider = ({ children }) => {
  const { user } = useAuth();
  const { loading, data } = useData();
  const { date: dateInfo, habits, reviews } = data;

  const [weeks, setWeeks] = useState([]);

  const parseData = () => {
    const { start_day } = JSON.parse(user.tracker_settings);
    const { start, limit } = calculateStartAndLimit(data, 52);
    const weeks = dateAndWeekGenerator({ start, limit, momentFirstReview: dateInfo.momentFirstReview, start_day });
    return weeks;
  };

  const getScores = weeks => {
    const weeksWithScores = weeks.map((week, index) => {
      const weekReviews = reviews.filter(r => r.weekyear === week.weekYear);
      if (!weekReviews.length) {
        return { ...week, score: 0, improvement: 0, improvementColor: improvementColor(0), hasReviews: false };
      }

      const weekScore = calculateScore(habits, weekReviews);

      const lastWeekReviews = index === 0 ? false : reviews.filter(r => r.weekyear === weeks[index - 1].weekYear);
      const lastWeekScore = lastWeekReviews ? calculateScore(habits, lastWeekReviews) : false;

      const improvement = calculateImprovement(weekScore, lastWeekScore);

      return {
        ...week,
        score: weekScore,
        improvement: improvement,
        improvementColor: improvementColor(improvement),
        hasReviews: true,
      };
    });

    return weeksWithScores;
  };

  const alreadyReviewThisWeek = dateInfo ? dateInfo.lastReview === weekYearToNumber(getWeekYear()) : false;
  //even if you send this weeks review since its not equal it will say that you missed last weeks review
  const missedLastReview =
    dateInfo && dateInfo.lastReview < weekYearToNumber(getWeekYear({ numOfWeeks: -1 })) ? true : false;
  const todayIsReviewDays = moment().day() === 5 || moment().day() === 6 || moment().day() === 0;
  const todayIsStartOfWeek = moment().day() === 1 || moment().day() === 2;

  useEffect(() => {
    const getWeeks = () => {
      const weeks = getScores(parseData());
      setWeeks(weeks);
    };

    !loading && !!reviews.length && getWeeks();
  }, [data, loading]);

  return (
    <TableContext.Provider
      value={{
        weeks,
        hasHabits: !!habits.length,
        reviewData: {
          alreadyReviewThisWeek,
          missedLastReview,
          todayIsReviewDays,
          todayIsStartOfWeek,
        },
        parseData,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
