import React from 'react';
import * as S from './styled';

const StreakLine = ({ streak, streakInitialIndex, index }) => (
  <S.Container
    width={77 * streak}
    ml={streakInitialIndex === 0 ? streakInitialIndex + streak + 2 : streakInitialIndex * 80 + streak + 2}
  >
    <S.Text>{`${streak}x streak`}</S.Text>
    <S.LineContainer>
      <S.Dot ml={5} />
      <S.Line />
      <S.Dot mr={5} />
    </S.LineContainer>
  </S.Container>
);

export const StreakIndicator = ({ scores }) => {
  /*
0 - 0 - 0 is not a streak
0 - 0 - 1 (1x streak)
1 - 1- 1 (3x streak)
1 - 2 - 2 (3x streak)
1 - 2- 3 (3x streak)
*/

  const streaks = [];

  let streak = 1;

  let streakInitialIndex = 0;

  for (let i = 1; i < scores.length; i++) {
    const currentScore = scores[i];
    const prevScore = scores[i - 1];
    if (currentScore >= prevScore) {
      if (currentScore > 0 && prevScore >= 0) {
        if (streak === 1) streakInitialIndex = i - 1;
        streak = prevScore === 0 ? streak : streak + 1;
      }
      if (streak > 1 && i === scores.length - 1) {
        streaks.push(<StreakLine key={i} streak={streak} streakInitialIndex={streakInitialIndex} />);
      }
    } else {
      if (streak > 1) {
        streaks.push(<StreakLine key={i} streak={streak} streakInitialIndex={streakInitialIndex} />);
      }
      streak = 1;
    }
  }

  return streaks.map(streak => streak);
};
