import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAuth } from '../../../context/AuthContext';
import { useData } from '../../../context/DataContext';

import { colorParser, prepareScores, weekYearToNumber } from '../../../helpers/tracker-parsers/parsers';
import { calculateLastFourWeekYears } from '../../../helpers/tracker-parsers/helpers';

import HabitsPeriodWrapper from '../HabitsPeriodWrapper';
import HabitName from '../HabitName';
import HabitStatus from '../HabitStatus';
import HabitFrequency from '../HabitFrequency';
import HabitScore from '../HabitScore';
import PartnerMessage from '../PartnerMessage';
import { StreakIndicator } from '../Streaks/index';

import * as S from './styled';
import HabitNotes from '../HabitNotes';

const Table = () => {
  const { isPrivacyActive, disablePrivacy } = useAuth();
  const { loading, partnerData } = useData();

  const { habits, reviews, scores } = partnerData;

  const [weeks, setWeeks] = useState(null);

  useEffect(() => {
    const getWeeks = () => {
      const weeks = calculateLastFourWeekYears();
      setWeeks(weeks);
    };

    !loading && !!reviews.length && getWeeks();
  }, [partnerData, loading]);

  if (!habits.length)
    return (
      <S.Container>
        <S.HabitsSubtitle table="standard">Friends' habits</S.HabitsSubtitle>

        <p className="text-left text-600" style={{ marginLeft: '1.5rem' }}>
          <FontAwesomeIcon icon="exclamation-triangle" className="mr-2" />
          If someone adds you as an Accountability Partner, their habit that are due today will appear here.
        </p>
      </S.Container>
    );

  const checkIfHabitIsToday = habit => {
    const today = Number(moment().format('d'));
    const frequency = JSON.parse(habit.frequency);

    if (frequency.repeat.type === 'daily' && frequency.repeat.days.includes(today)) {
      return true;
    }
    if (frequency.repeat.type === 'weekly' || frequency.repeat.type === 'everyday') {
      return true; //always returning if weekly or everyday because log logic is off
    }

    return false;
  };

  return (
    <S.Container privacyMode={isPrivacyActive}>
      {isPrivacyActive && <S.DisablePrivacyMode onClick={disablePrivacy}>Disable Privacy Mode</S.DisablePrivacyMode>}

      <S.SubtitlesContainer table="standard">
        <S.HabitsSubtitle table="standard">Friends' habits</S.HabitsSubtitle>

        <h5 id="weeks-subtitle" size={20}>
          Past 4 weeks
        </h5>
      </S.SubtitlesContainer>

      <S.Header className="text-600" table="standard">
        <div className="weeks">{weeks && weeks.map((el, i) => <span key={i}>{el.date}</span>)}</div>
      </S.Header>

      {weeks &&
        [...new Set(habits.map(el => el.friend_name))].map((friend, friendIndex) => {
          const friendId = habits.find(el => el.friend_name === friend).friend_id;

          const friendsScore = scores.find(score => score.user_id === friendId) || { score: 0 };
          return (
            <HabitsPeriodWrapper key={friendIndex} friend={friend} friendsScore={friendsScore} startHidden={false}>
              {habits
                .filter(h => h.friend_name === friend)
                .map(habit => {
                  const habitReviews = reviews.filter(review => review.habit_id === habit.id);

                  const scoresOfHabit = prepareScores(weeks, habitReviews);

                  const reviewsWithInsights = habitReviews
                    .sort((a, b) => weekYearToNumber(b.weekyear) - weekYearToNumber(a.weekyear))
                    .filter(r => r.insights !== null);

                  const today = checkIfHabitIsToday(habit);

                  return (
                    <S.Row key={habit.id} style={{ filter: !habit.active && 'opacity(0.5)' }} table="standard">
                      <HabitName habit={habit} friend />

                      <HabitFrequency
                        frequency={JSON.parse(habit.frequency)}
                        friendSettings={habit.friend_settings ? JSON.parse(habit.friend_settings) : false}
                        today={today}
                      />

                      <div className="status">
                        <HabitStatus reviews={habitReviews} />
                      </div>

                      <div className="partner">
                        <PartnerMessage habit={habit} />
                      </div>

                      <HabitNotes
                        habit={habit}
                        insight={
                          !!reviewsWithInsights.length
                            ? reviewsWithInsights[0].insights
                            : 'There is no note from last week.'
                        }
                      />

                      <div className="scores">
                        <StreakIndicator scores={scoresOfHabit} />
                        {weeks.map(week => {
                          const weekReview = habitReviews.find(review => review.weekyear === week.weekyear);
                          if (weekReview) {
                            return (
                              <HabitScore
                                key={`${habit.id}-${week}`}
                                review={weekReview}
                                deactivate={!habit.active || !weekReview}
                                background={colorParser(weekReview.score)}
                                friend
                              />
                            );
                          } else if (!habit.active) {
                            return (
                              <HabitScore key={`${habit.id}-${week}`} background={'#d9d9d9'} deactivate={true} friend />
                            );
                          } else {
                            return <HabitScore key={`${habit.id}-${week}`} background={'	'} deactivate={true} friend />;
                          }
                        })}
                      </div>
                    </S.Row>
                  );
                })}
            </HabitsPeriodWrapper>
          );
        })}
    </S.Container>
  );
};

export default Table;
