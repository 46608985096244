import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useAuth } from '../../../context/AuthContext';
import { useData } from '../../../context/DataContext';
import { useTable } from '../../../context/TableContext';
import { useLogs } from '../../../context/LogsContext';

import { weekYearToNumber, colorParser, prepareScores } from '../../../helpers/tracker-parsers/parsers';
import { getWeekYear, checkIfCorrectAlertWeek } from '../../../helpers/tracker-parsers/helpers';

import HabitName from '../HabitName';
import HabitStatus from '../HabitStatus';
import HabitScore from '../HabitScore';
import AccountabilityPartner from '../AccountabilityPartner';
import AddHabitRow from '../AddHabitRow';
import ProgressScore from '../ProgressScore';

import * as S from './styled';

import HabitsPeriodWrapper from '../HabitsPeriodWrapper';
import HabitFrequency from '../HabitFrequency';
import HabitNotes from '../HabitNotes';
import { StreakIndicator } from '../Streaks/index';

export default function StandardTabl() {
  const { isPrivacyActive, disablePrivacy } = useAuth();
  const { weeks: allWeeks, reviewData } = useTable();
  const { todayHabits, otherHabits, handleLogClick } = useLogs();

  const { data } = useData();
  const { habits, reviews, date: dateInfo } = data;

  const [weeks, setWeeks] = useState([]);
  const [editingHabit, setEditingHabit] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const canReviewNow =
    (reviewData.todayIsReviewDays && !reviewData.alreadyReviewThisWeek) || reviewData.missedLastReview;

  useEffect(() => {
    const processWeeks = () => {
      const allWeeksCopy = [...allWeeks];
      const currentWeekyear = weekYearToNumber(getWeekYear());
      const aWeekAgo = weekYearToNumber(getWeekYear({ numOfWeeks: -1 }));
      const twoWeeksAgo = weekYearToNumber(getWeekYear({ numOfWeeks: -2 }));

      let lastPassedWeek = 0;
      if (dateInfo !== null) {
        if (dateInfo.lastReview === currentWeekyear) {
          lastPassedWeek = currentWeekyear;
        } else if (dateInfo.lastReview === aWeekAgo) {
          lastPassedWeek = aWeekAgo;
        } else {
          lastPassedWeek = twoWeeksAgo;
        }

        const lastReviewIndex = allWeeksCopy.findIndex(w => weekYearToNumber(w.weekYear) === lastPassedWeek);

        const lastFourWeeks = allWeeksCopy.slice(lastReviewIndex - 3, lastReviewIndex + 1);

        if (!lastFourWeeks.length) {
          const nextFourWeeks = allWeeksCopy.slice(0, 4);
          setWeeks(nextFourWeeks);
          return;
        }
        setWeeks(lastFourWeeks);
      }
    };

    dateInfo && processWeeks();
  }, [allWeeks]);

  const handleRowClick = (e, habit) => {
    if (e.target.classList.contains('updateLog')) {
      handleLogClick(habit);
    }
  };

  const periodSorter = period => {
    if (period === 'previous') return -1;
    if (period === 'now') return 0;
    if (period === 'later') return 1;
  };

  return (
    <S.Container privacyMode={isPrivacyActive}>
      {isPrivacyActive && <S.DisablePrivacyMode onClick={disablePrivacy}>Disable Privacy Mode</S.DisablePrivacyMode>}
      <S.SubtitlesContainer table="standard" advWeeks={weeks && weeks.length}>
        <S.HabitsSubtitle table="standard">My habits</S.HabitsSubtitle>
        <h5 id="weeks-subtitle" size={weeks && weeks.length * 5}>
          Your {habits.length > 0 && weeks ? weeks.length : 0}-week progress
        </h5>
      </S.SubtitlesContainer>
      <S.Header className="text-600" table="standard">
        <div className="weeks">
          {habits.length > 0 &&
            weeks &&
            weeks.map((el, i) => (
              <span key={i}>
                {el.week} - {el.date}
              </span>
            ))}
        </div>
      </S.Header>

      {weeks &&
        [...new Set(todayHabits.map(el => el.period.title))]
          .sort((a, b) => periodSorter(a) - periodSorter(b))
          .map((period, periodIndex, periodsArr) => {
            const isLast = otherHabits.length === 0 && periodIndex === periodsArr.length - 1;

            return (
              <HabitsPeriodWrapper
                key={period}
                period={period}
                startHidden={todayHabits.length + otherHabits.length <= 7 ? false : period !== 'now'}
                isLast={isLast}
              >
                {todayHabits
                  .filter(h => h.period.title === period)
                  .map(habit => {
                    const frequency = JSON.parse(habit.frequency);

                    const habitReviews = reviews.filter(review => review.habit_id === habit.id);

                    const scoresOfHabit = prepareScores(weeks, habitReviews);

                    const reviewsWithInsights = habitReviews
                      .sort((a, b) => weekYearToNumber(b.weekyear) - weekYearToNumber(a.weekyear))
                      .filter(r => r.insights !== null);

                    return (
                      <S.Row
                        key={habit.id}
                        style={{ filter: !habit.active && 'opacity(0.5)' }}
                        table="standard"
                        finished={habit.finished}
                        onClick={e => handleRowClick(e, habit)}
                      >
                        <HabitName
                          table="standard"
                          habit={habit}
                          today
                          editingHabit={editingHabit}
                          setEditingHabit={setEditingHabit}
                        />

                        <HabitFrequency
                          frequency={frequency}
                          prefix={habit.period.prefix}
                          periodColor={habit.period.color}
                          today
                        />

                        <div className="status updateLog">
                          <HabitStatus reviews={habitReviews} />
                        </div>
                        <div className="partner">
                          <AccountabilityPartner habit={habit} />
                        </div>

                        <HabitNotes
                          habit={habit}
                          insight={
                            !!reviewsWithInsights.length
                              ? reviewsWithInsights[0].insights
                              : 'There is no note from last week.'
                          }
                        />

                        {/* <div className="spacing updateLog" /> */}

                        <div className="scores">
                          <StreakIndicator scores={scoresOfHabit} />
                          {weeks.map((week, weekIndex) => {
                            const weekReview = habitReviews.find(review => review.weekyear === week.weekYear);
                            const isWeekAtLeastOneWeekAgo = moment(moment(week.momentObj).add(1, 'week')).isBefore(
                              moment()
                            );
                            if (weekReview || isWeekAtLeastOneWeekAgo) {
                              return (
                                <HabitScore
                                  table="standard"
                                  habit={habit}
                                  key={`${habit.id}-${week.week}`}
                                  review={weekReview}
                                  noReview={!weekReview}
                                  noReviewData={{
                                    habit_id: habit.id,
                                    weekyear: week.weekYear,
                                  }}
                                  deactivate={!habit.active}
                                  background={weekReview ? colorParser(weekReview.score) : '#e8e8e8'}
                                />
                              );
                            } else if (!habit.active) {
                              return (
                                <HabitScore
                                  table="standard"
                                  habit={habit}
                                  key={`${habit.id}-${week.week}`}
                                  background={'#d9d9d9'}
                                  deactivate={true}
                                />
                              );
                            } else {
                              return (
                                <HabitScore
                                  table="standard"
                                  habit={habit}
                                  key={`${habit.id}-${week.week}`}
                                  background={'	'}
                                  deactivate={
                                    canReviewNow
                                      ? !checkIfCorrectAlertWeek(
                                          week,
                                          reviewData.missedLastReview && reviewData.todayIsStartOfWeek
                                        )
                                      : true
                                  }
                                  goToReview={
                                    canReviewNow &&
                                    checkIfCorrectAlertWeek(
                                      week,
                                      reviewData.missedLastReview && reviewData.todayIsStartOfWeek
                                    )
                                  }
                                />
                              );
                            }
                          })}
                        </div>
                      </S.Row>
                    );
                  })}
              </HabitsPeriodWrapper>
            );
          })}
      {weeks && !!otherHabits.length && (
        <HabitsPeriodWrapper
          period="other"
          startHidden={todayHabits.length + otherHabits.length <= 7 ? false : true}
          isLast={true}
        >
          {otherHabits.map(habit => {
            const frequency = JSON.parse(habit.frequency);
            const habitReviews = reviews.filter(review => review.habit_id === habit.id);
            const reviewsWithInsights = habitReviews
              .sort((a, b) => weekYearToNumber(b.weekyear) - weekYearToNumber(a.weekyear))
              .filter(r => r.insights !== null);

            const scoresOfHabit = prepareScores(weeks, habitReviews);

            return (
              <S.Row key={habit.id} style={{ filter: !habit.active && 'opacity(0.5)' }} table="standard">
                <HabitName
                  table="standard"
                  habit={habit}
                  editingHabit={editingHabit}
                  setEditingHabit={setEditingHabit}
                />

                <HabitFrequency frequency={frequency} />

                <div className="status">
                  <HabitStatus reviews={habitReviews} />
                </div>
                <div className="partner">
                  <AccountabilityPartner habit={habit} />
                </div>

                <HabitNotes
                  habit={habit}
                  insight={
                    !!reviewsWithInsights.length ? reviewsWithInsights[0].insights : 'There is no note from last week.'
                  }
                />

                <div className="scores">
                  <StreakIndicator scores={scoresOfHabit} />
                  {weeks.map((week, weekIndex) => {
                    const weekReview = habitReviews.find(review => review.weekyear === week.weekYear);
                    const isWeekAtLeastOneWeekAgo = moment(moment(week.momentObj).add(1, 'week')).isBefore(moment());
                    if (weekReview || isWeekAtLeastOneWeekAgo) {
                      return (
                        <HabitScore
                          table="standard"
                          habit={habit}
                          key={`${habit.id}-${week.week}`}
                          review={weekReview}
                          noReview={!weekReview}
                          noReviewData={{
                            habit_id: habit.id,
                            weekyear: week.weekYear,
                          }}
                          deactivate={!habit.active}
                          background={weekReview ? colorParser(weekReview.score) : '#e8e8e8'}
                        />
                      );
                    } else if (!habit.active) {
                      return (
                        <HabitScore
                          table="standard"
                          habit={habit}
                          key={`${habit.id}-${week.week}`}
                          background={'#d9d9d9'}
                          deactivate={true}
                        />
                      );
                    } else {
                      return (
                        <HabitScore
                          table="standard"
                          habit={habit}
                          key={`${habit.id}-${week.week}`}
                          background={'	'}
                          deactivate={
                            canReviewNow
                              ? !checkIfCorrectAlertWeek(
                                  week,
                                  reviewData.missedLastReview && reviewData.todayIsStartOfWeek
                                )
                              : true
                          }
                          goToReview={
                            canReviewNow &&
                            checkIfCorrectAlertWeek(week, reviewData.missedLastReview && reviewData.todayIsStartOfWeek)
                          }
                        />
                      );
                    }
                  })}
                </div>
              </S.Row>
            );
          })}
        </HabitsPeriodWrapper>
      )}
      <S.Footer>
        <AddHabitRow
          editingHabit={editingHabit}
          setEditingHabit={setEditingHabit}
          isActive={isActive}
          setIsActive={setIsActive}
        />
        {habits.length > 0 &&
          weeks &&
          !editingHabit &&
          !isActive &&
          weeks.map((el, i) => <ProgressScore table="standard" week={el} key={i} />)}
      </S.Footer>
    </S.Container>
  );
}
