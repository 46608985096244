import React, { useState } from 'react';
import { useData } from '../../../context/DataContext';

import ButtonIcon from '../../common/ButtonIcon';

import ReflectModal from '../ReflectModal';
import ScoreNumber from '../ScoreNumber';

import { statusCalculator } from '../../../helpers/tracker-parsers/parsers';

import * as S from './styled';
import { toast } from 'react-toastify';

const HabitReview = ({ habit, reviewInput, reviewInputs, setReviewInputs }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [note, setNote] = useState('');
  const { data } = useData();

  const handleChange = () => {
    if (note === '') {
      if (!dismissable) {
        toast.error('You are required to add a note since this habit is no longer on track.');
        return;
      }
    }
    const updatedReviewInput = { ...reviewInput, insights: note };
    const newReviewInputs = [...reviewInputs];
    newReviewInputs.splice(reviewInput.index, 1, updatedReviewInput);
    setReviewInputs(newReviewInputs);
    setIsOpen(false);
  };

  const habitsNewReview = reviewInputs.filter(review => review.habit_id === habit.id);

  const habitsOlderReviews = data.reviews.filter(review => review.habit_id === habit.id);

  const habitsAllReviews = [habitsNewReview, ...habitsOlderReviews];

  const newHabitStatus = statusCalculator(habitsAllReviews);

  const dismissable = newHabitStatus === 'On track';

  const handleScoreChange = value => {
    const updatedReviewInput = { ...reviewInput, score: value };
    const newReviewInputs = [...reviewInputs];
    newReviewInputs.splice(reviewInput.index, 1, updatedReviewInput);
    setReviewInputs(newReviewInputs);
    if (!dismissable) setIsOpen(true);
  };

  if (!habit.active) {
    return <S.ReviewContainer className="notActive" />;
  }

  const toggleOpen = () => {
    if (dismissable) {
      setIsOpen(!isOpen);
      return;
    }

    if (note === '') {
      toast.error('You are required to add a note since this habit is no longer on track.');
    }
  };

  return (
    <S.ReviewContainer>
      <S.ScoreLabel>Select your achievement:</S.ScoreLabel>
      <ScoreNumber
        habit={habit}
        currentScore={reviewInput && reviewInput.score}
        changeScore={handleScoreChange}
        newReview
      />
      <ButtonIcon size="sm" icon="plus" onClick={() => setIsOpen(true)} className="ml-2 fs--2 btn-reflect">
        Note
      </ButtonIcon>
      <ReflectModal
        note={note}
        dismissable={dismissable}
        habitName={habit.name}
        reviewInput={reviewInput}
        handleChange={e => setNote(e.target.value)}
        handleSubmit={handleChange}
        isOpen={isOpen}
        toggleOpen={() => toggleOpen()}
      />
    </S.ReviewContainer>
  );
};

export default HabitReview;
